<template>
  <div>
    <div class="headerH5">header</div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'h5Layout',
  data() {
    return {}
  },
}
</script>